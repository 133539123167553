import React from 'react';
import { Table } from '~/components/Table/Table';
import { EnrichedCustomerAssetRow } from '~/pages/CustomerAssets/types';
import { useTranslation } from 'react-i18next';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';

import { formatDate } from '~/utils/date-utils';
import Box from '@mui/material/Box';
import { VLifeTimelinePeriod } from '~/__generated-asset__/graphql';

interface CustomerAssetsExpandableRowProps {
  row: EnrichedCustomerAssetRow;
}

export const CustomerAssetsExpandableRow = ({ row }: CustomerAssetsExpandableRowProps) => {
  const { t } = useTranslation('customer-assets-expandable-row');
  const vLifeTimeline = row['vLifeTimeline.timeline'];

  if (!vLifeTimeline.length) {
    return <></>;
  }

  const activeStatusText = t('licence-type');
  const startDateText = t('start-date');
  const endDateText = t('end-date');

  const mapVLifeConfigToTableRow = (timelinePeriod: VLifeTimelinePeriod) => {
    const { startDate, endDate, status } = timelinePeriod;

    return {
      _id: `${startDate}-${endDate}`,
      [activeStatusText]: {
        label: <StatusPill status={status} />,
      },
      [startDateText]: formatDate(startDate),
      [endDateText]: formatDate(endDate),
    };
  };

  const data = vLifeTimeline.map(mapVLifeConfigToTableRow);
  console.log(data);

  return (
    <Box
      sx={{
        padding: 4,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Table
        muted
        embedded={false}
        data={data ?? []}
        omittedKeys={['_id']}
        itemsPerPage={100}
        omittedSortKeys={[activeStatusText, startDateText, endDateText]}
      />
    </Box>
  );
};
